<template>
  <div class="shadow-[0_4px_3px_rgba(0,0,0,0.25)] mx-auto md:max-w-[96%] lg:w-[1240px] my-sm md:my-2xl bg-white pt-xs">
    <Tabs :active-tab="isSignupTab" align="center" border-bottom class="text-charcoal-default">
      <Tab data-test-id="signInTab" title="Sign In">
        <Login />
      </Tab>
      <Tab data-test-id="createAccountTab" title="Create Account">
        <br-component component="right" />
      </Tab>
    </Tabs>
  </div>
</template>

<script lang="ts" setup>
definePageMeta({
  middleware: ["is-not-authenticated"],
});

const route = useRoute();
const isSignupTab = computed(() => (route.query.signup ? 1 : 0));

useHead({
  bodyAttrs: {
    class: "bg-neutral-lighter",
  },
});
</script>
