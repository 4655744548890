<template>
  <Stack direction="col" align="center" justify="center" :breakpoints="mainContainerBreakpoints" gap="lg" class="my-xl">
    <Stack direction="col" class="px-xs w-full md:w-[516px]" gap="md">
      <Banner
        data-test-id="credentialsError"
        v-if="showFormErrorMessage"
        @dismiss="showFormErrorMessage = false"
        variant="error"
        data-fs="loginCredentialsError"
      >
        We’re sorry but we don’t recognise the email address or password you’ve entered. Please check your details and
        try again.
      </Banner>
      <Banner v-if="passwordReset" variant="success">
        Your password has been successfully reset. You can now sign in with your new password.
      </Banner>
      <form class="w-full flex flex-col gap-md" @submit.prevent="handleLogin">
        <Input
          id="email"
          data-test-id="email"
          size="md"
          :model-value="email"
          label="Email Address"
          data-validation-rule="email"
          @update:input-value="email = String($event).trim()"
          @blur="vee.validateSingleField($event)"
          :error="vee.fieldHasError('email').value"
          :message-text="vee.fieldError('email').value"
          data-fs="loginEmailInput"
        />
        <Input
          id="password"
          data-test-id="password"
          size="md"
          :model-value="password"
          label="Password"
          is-password
          link-text="Forgotten password?"
          link-href="/forgotten-password"
          link-class="text-mkm-blue-light text-sm"
          :link-tag="NuxtLink"
          @update:input-value="password = String($event)"
          data-validation-rule="password"
          @blur="vee.validateSingleField($event)"
          :error="vee.fieldHasError('password').value"
          :message-text="vee.fieldError('password').value"
        />

        <Stack direction="col" class="w-full" gap="none">
          <Button
            data-test-id="signInButton"
            size="md"
            variant="secondary"
            class="min-w-full justify-center"
            data-fs="loginButton"
            :disabled="signInLoading"
          >
            SIGN IN
            <Spinner v-if="signInLoading" />
          </Button>
        </Stack>
      </form>
    </Stack>
    <!-- Login Rationale -->
    <br-component component="left" />
  </Stack>
</template>

<script setup lang="ts">
import useRouterHistory from "~/composables/useRouterHistory";
import { ROUTES } from "~/helpers/routes/routes";

const NuxtLink = resolveComponent("NuxtLink");
const router = useRouter();

const { login, user } = useUser();
const { selectBranchFromCurrentUser } = useBranchCurrentUser();
const { routerHistory } = useRouterHistory();
const vee = useVeeValidate();
const route = useRoute();

const showFormErrorMessage = ref<boolean>(false);
const mainContainerBreakpoints: Object = { align: { md: "start" }, direction: { md: "row" }, gap: { md: "3xl" } };
const email = ref<string>("");
const password = ref<string>("");
const signInLoading = ref<boolean>(false);

const passwordReset = ref<boolean>(false);

onMounted(() => {
  if (route.query.q === "passwordResetSuccess") {
    passwordReset.value = true;
  }
});

const handleLogin = async () => {
  await vee.validateAllFields({ email: email.value, password: password.value });
  if (Object.keys(vee.errors.value).length > 0) return;

  try {
    signInLoading.value = true;

    await login({
      email: email.value,
      password: password.value,
      isCheckout: false,
    });

    if (user.value) {
      const previousPath = routerHistory.value as ROUTES;
      const noReturnRoutes = [
        ROUTES.CREATE_CASH_SUCCESS,
        ROUTES.SET_PASSWORD,
        ROUTES.RESET_PASSWORD,
        ROUTES.FORGOTTEN_PASSWORD,
      ];

      router.push(previousPath && !noReturnRoutes.includes(previousPath) ? previousPath : ROUTES.ACCOUNT_DASHBOARD);

      await selectBranchFromCurrentUser();
      showFormErrorMessage.value = false;
    }
  } catch {
    showFormErrorMessage.value = true;
  } finally {
    signInLoading.value = false;
  }
};
</script>
